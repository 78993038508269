import { useRef, useState } from 'react';
import { ArrowPathOutlined, RangePicker, Row, Tooltip } from 'tera-dls';

import {
  LIMIT_MAX_DATE_TIME,
  LIMIT_MIN_DATE_TIME,
} from '_common/constants/common';
import moment from 'moment';
import ChartPieCourseOrder, {
  ChartPieCourseOrderRefs,
} from '../ChartPieCourseOrder';
import ChartPieSaleOrder, { ChartPieSaleOrderRefs } from '../ChartPieSaleOrder';

const ChartPieOrderReport = () => {
  const [date, setDate] = useState<any>([
    moment().subtract(1, 'months'),
    moment(),
  ]);
  const ChartPieSaleOrderRefs = useRef<ChartPieSaleOrderRefs>(null);
  const ChartPieCourseOrderRefs = useRef<ChartPieCourseOrderRefs>(null);

  const handleRefetch = () => {
    ChartPieSaleOrderRefs.current.refetchData();
    ChartPieCourseOrderRefs.current.refetchData();
  };

  return (
    <div className="p-2.5 rounded-md border bg-white">
      <div className="flex justify-end gap-2.5 mb-2.5">
        <Tooltip title="Làm mới">
          <span className="flex items-center">
            <ArrowPathOutlined
              className="cursor-pointer w-4 h-4"
              onClick={handleRefetch}
            />
          </span>
        </Tooltip>
        <RangePicker
          placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
          className="w-[300px]"
          allowClear={false}
          value={date}
          onChange={setDate}
          maxDate={moment(LIMIT_MAX_DATE_TIME)}
          minDate={moment(LIMIT_MIN_DATE_TIME)}
        />
      </div>
      <Row className="grid-cols-2 gap-2.5">
        <ChartPieSaleOrder
          params={{ date }}
          chartClassName="!h-[350px]"
          ref={ChartPieSaleOrderRefs}
        />
        <ChartPieCourseOrder
          params={{ date }}
          chartClassName="!h-[350px]"
          ref={ChartPieCourseOrderRefs}
        />
      </Row>
    </div>
  );
};

export default ChartPieOrderReport;
