import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const OrderReportEndpoint = `${endpoint}/bake-teach/report/invoice`;
const OrderReportApi = {
  getOverview: async () =>
    await api
      .get(`${OrderReportEndpoint}/overview`)
      .then((data) => data?.data?.data),
  getChartPie: async ({ params }) =>
    await api
      .get(`${OrderReportEndpoint}/pie`, params)
      .then((data) => data?.data?.data),
  getChartBar: async ({ params }) =>
    await api
      .get(`${OrderReportEndpoint}/chart`, params)
      .then((data) => data?.data?.data),
};
export default OrderReportApi;
