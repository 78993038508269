import { useQuery } from '@tanstack/react-query';
import DetectMedia from '_common/component/DetectMedia';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { renderRating } from '_common/utils/utils';
import { observer } from 'mobx-react-lite';
import EvaluateApi from 'pages/CourseManagement/Course/api/Evaluate';
import { useEffect } from 'react';
import imageDefault from 'styles/images/image-default.png';
import { Button, formatDate, Image, Modal, Spin } from 'tera-dls';

interface IProps {
  open: boolean;
  value?: string | number;
  onClose: () => void;
}

const StudentEvaluate = (props: IProps) => {
  const { open, value: id, onClose } = props;
  const {
    data: detail,
    refetch: fetchData,
    isLoading,
  } = useQuery(
    ['get-evaluate-detail', id],
    () => EvaluateApi.getDetail({ id }),
    {
      staleTime: 300000,
      cacheTime: 300000,
      enabled: !!id,
    },
  );

  useEffect(() => {
    id && fetchData();
  }, [id]);

  return (
    <>
      <Modal
        open={open}
        title={'Chi tiết đánh giá khóa học'}
        closeIcon={false}
        width={600}
        onCancel={onClose}
        footer={
          <Button onClick={onClose} className="px-5">
            Đóng
          </Button>
        }
      >
        <Spin spinning={isLoading}>
          <div className="rounded-[10px] border border-blue-500 p-2.5">
            <div className="space-y-2.5">
              <div className="flex gap-2.5 items-center">
                <Image
                  defaultImage={imageDefault}
                  src={detail?.user?.avatar}
                  containerClassName="border !size-[40px] rounded-full overflow-hidden"
                  imageClassName="object-cover"
                />
                <div className="space-y-2">
                  <span className="line-clamp-1 text-blue-600 font-bold">
                    {detail?.user?.full_name}
                  </span>
                  <div className="flex gap-2.5 items-center">
                    {renderRating(detail?.star_count)}
                    <span className="size-[3px] bg-gray-700 rounded-full" />
                    <div className="leading-[17px] text-[#374151]">
                      {formatDate(detail?.created_at, DATE_TIME_FORMAT)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-2.5">
                <p className="break-word text-xs leading-[20px] text-gray-700">
                  {detail?.content}
                </p>
                <div className="flex gap-2.5 items-center">
                  {detail?.attachments?.map((file) => (
                    <DetectMedia
                      src={file?.url}
                      type={file?.type_file}
                      className="w-[80px] h-[80px] rounded overflow-hidden"
                    />
                  ))}
                </div>
              </div>
              <div className="flex flex-col ml-5 border-l-2 pl-2.5">
                {detail?.children?.map((reply) => (
                  <div className="flex flex-col gap-y-1 hover:bg-gray-100 p-1">
                    <div className="flex items-center gap-x-2.5 justify-between">
                      <div className="flex items-center gap-x-2.5">
                        <img
                          src={reply?.user?.avatar}
                          className="w-6 h-6 border rounded-full"
                          alt=""
                        />
                        <p className="font-medium">{reply?.user?.full_name}</p>
                      </div>
                      <p className="text-xs text-gray-500">
                        {formatDate(reply?.created_at, DATE_TIME_FORMAT)}
                      </p>
                    </div>
                    <div>{reply?.content}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default observer(StudentEvaluate);
