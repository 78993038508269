export const COURSE_ORDER_STATUS = {
  not_yet_paid: 'Chờ duyệt',
  approved: 'Đã duyệt',
  fail: 'Đã hủy',
};

export const COURSE_ORDER_STATUS_COLOR = {
  not_yet_paid: 'yellow03',
  approved: 'green03',
  fail: 'gray01',
};

export const COURSE_ORDER_COMPLETE_STATUS = {
  unfinished: 'Chưa hoàn thành',
  completed: 'Đã hoàn thành',
  refund: 'Hoàn tiền',
  cancel: 'Đã hủy',
};

export const COURSE_ORDER_COMPLETE_STATUS_COLOR = {
  unfinished: 'yellow03',
  completed: 'green03',
  refund: 'red03',
};

export const COURSE_ORDER_COMPLETE_STATUS_COLOR_CHART = {
  unfinished: '#E3A008',
  completed: '#31C48D',
  refund: '#76A9FA',
  cancel: '#F98080',
};

export const COURSE_ORDER_FINISH_STATUS = {
  1: 'Đã hoàn thành',
  2: 'Chưa hoàn thành',
};

export const COURSE_ORDER_FINISH_STATUS_COLOR = {
  1: 'green03',
  2: 'yellow03',
};

export const COURSE_ORDER_REJECT_REASON = {
  1: 'Lịch trình không phù hợp',
  2: 'Mục tiêu học tập thay đổi',
  3: 'Nội dung khóa học lỗi thời',
  4: 'Khóa học không phù hợp với mong muốn',
  5: 'Khó khăn tài chính',
  6: 'Lý do khác',
};
