import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ButtonCommon from '_common/component/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  Dropdown,
  EllipsisHorizontalOutlined,
  getQueryParams,
  notification,
  PencilSquareOutlined,
  Spin,
  TabItemType,
  Tabs,
  updateQueryParams,
  XMarkOutlined,
} from 'tera-dls';
import ProductApi from '../../api';
import { PRODUCT_URL } from '../../url';
import DescriptionProduct from './containers/Description';
import GeneralProduct from './containers/General';
import ListImageProduct from './containers/ListImage';
import useConfirm from '_common/hooks/useConfirm';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { useEffect } from 'react';

const ProductDetail = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const { id } = useParams();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const handleUpdateQueryParams = (data: Record<string, any>): void => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const {
    data: dataDetail,
    isFetching,
    refetch,
  } = useQuery(['get-product-detail', id], () => ProductApi.getDetail(id), {
    staleTime: 300000,
    cacheTime: 300000,
    enabled: !!id,
  });

  useEffect(() => {
    !!id && refetch();
  }, [id]);

  const tabItems: TabItemType[] = [
    {
      key: 'info',
      label: <span className="tab-table">Thông tin chi tiết</span>,
    },
    { key: 'describe', label: <span className="tab-table">Mô tả</span> },
    {
      key: 'list-image',
      label: <span className="tab-table">Ảnh sản phẩm</span>,
    },
  ];

  const handleChangeTabs = (currentTab: string) => {
    handleUpdateQueryParams({ tab: currentTab });
  };

  const nodeContent = {
    info: <GeneralProduct dataDetail={dataDetail} />,
    describe: <DescriptionProduct dataDetail={dataDetail} />,
    'list-image': (
      <ListImageProduct
        defaultProductImages={dataDetail?.product_images}
        thumbnail={{ url: dataDetail?.image_url ?? '' }}
      />
    ),
  };

  const dropdownItems = [
    {
      key: '3',
      label: 'Xóa',
      onClick: () => 1,
    },
    {
      key: '4',
      label: 'Sửa',
      onClick: () => navigate(PRODUCT_URL.update.path + '/' + id),
    },
  ];

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => ProductApi.delete(variables),
    {
      onSuccess: (res: any) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-product-list']);
          notification.success({
            message: res?.msg,
          });
          navigate(PRODUCT_URL.list.path);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (id: number, name: string): void => {
    confirm.warning({
      title: 'Xoá sản phẩm',
      content: (
        <>
          <p>Bạn có chắc muốn xóa sản phẩm</p>
          <p>
            <span className="font-bold break-word">{name}</span> này không?
          </p>
        </>
      ),
      onOk: () => {
        mutateDelete({ id });
      },
    });
  };

  const buttonAction = (
    <>
      <div className={'hidden xl:flex xl:gap-x-2.5'}>
        <ButtonCommon
          icon={<PencilSquareOutlined className="w-4 h-4" />}
          onClick={() =>
            navigate(`${PRODUCT_URL.update.path}/${dataDetail.id}`)
          }
          className="page-header-btn"
        >
          Sửa
        </ButtonCommon>
        {dataDetail?.status == 'inactive' && (
          <ButtonCommon
            icon={<XMarkOutlined className="w-4 h-4" />}
            onClick={() => handleDelete(dataDetail?.id, dataDetail?.name)}
            type="danger"
            className="page-header-btn"
          >
            Xóa
          </ButtonCommon>
        )}
      </div>
      <div className={'xl:hidden'}>
        <Dropdown menu={{ items: dropdownItems }} trigger="click">
          <Button
            icon={<EllipsisHorizontalOutlined />}
            type="alternative"
            className="page-header-btn"
          />
        </Dropdown>
      </div>
    </>
  );

  return (
    <Spin spinning={isFetching}>
      <div className="tera-page-form !gap-0">
        <div className="page-header-sticky bg-[#F3F3F9]">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb
                separator={'/'}
                containerItemClassName="text-sm"
                items={[
                  {
                    title: (
                      <a className="text-blue-400 hover:text-blue-600">
                        Quản lý sản phẩm
                      </a>
                    ),
                    onClick: () => navigate(PRODUCT_URL.list.path),
                  },
                  {
                    title: 'Chi tiết sản phẩm',
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function">{buttonAction}</div>
          </div>
        </div>
        <div className=" page-content-v2 bg-white shadow-xsm rounded-md px-2.5 h-full mx-2.5 !mb-2.5 !gap-y-0">
          <Tabs
            onChange={handleChangeTabs}
            items={tabItems}
            activeKey={queryParams?.tab ?? 'info'}
          />
          <div>{nodeContent[queryParams?.tab ?? 'info']}</div>
        </div>
      </div>
    </Spin>
  );
};

export default ProductDetail;
