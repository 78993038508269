import React, { useState } from 'react';
import Content from './Content';
import moment from 'moment';
export const ChartBarCourseOrderContext = React.createContext(null);

const ChartBarCourseOrder = () => {
  const [contextParams, setContextParams] = useState({
    date: [moment().subtract(1, 'months'), moment()],
  });
  return (
    <ChartBarCourseOrderContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <Content chartClassName="!h-[350px]" type="small" />
    </ChartBarCourseOrderContext.Provider>
  );
};

export default ChartBarCourseOrder;
