export const ORDER_STATUS = {
  not_yet_paid: 'Chờ duyệt',
  approved: 'Đã duyệt',
  fail: 'Thất bại',
  complete: 'Hoàn thành',
  canceled: 'Đã hủy',
};

export const ORDER_STATUS_COLOR = {
  not_yet_paid: 'yellow03',
  approved: 'green03',
  fail: 'gray01',
  complete: 'blue03',
  canceled: 'red03',
};

export const ORDER_STATUS_COLOR_CHART = {
  not_yet_paid: '#E3A008',
  approved: '#76A9FA',
  fail: '#6B7280',
  complete: '#31C48D',
  canceled: '#F98080',
};

export const discountType = {
  not_available: 'Không có chiết khấu',
  fixed: 'Cố định',
  by_percentage: 'Theo % hóa đơn',
};

export const REJECT_REASON = {
  1: 'Khách hàng thay đổi ý định',
  2: 'Sai sản phẩm',
  3: 'Thời gian giao hàng quá lâu',
  4: 'Thay đổi địa chỉ giao hàng',
  5: 'Lý do khác',
};
