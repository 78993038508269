import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Col, formatNumber, Row, Spin } from 'tera-dls';
import CourseReportApi from '../../api';
import IconReport1 from 'styles/images/report/icon-report-1.png';
import IconReport2 from 'styles/images/report/icon-report-2.png';
import IconReport3 from 'styles/images/report/icon-report-3.png';

const Overview = () => {
  const { data, isLoading } = useQuery(
    ['get-course-report-overview'],
    () => CourseReportApi.getOverview(),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const overview = useMemo(() => {
    if (!data) return {};
    const result = data?.reduce((res, current) => {
      if (!current.classification) return res;
      return { ...res, [current.classification]: current.total };
    }, {});
    return result;
  }, [data]);

  return (
    <div className="rounded-[10px] p-2.5 bg-white border">
      <p className="uppercase text-gray-700 font-medium mb-2.5">Tổng quan</p>
      <Row className="grid-cols-3 gap-2.5">
        <Spin spinning={isLoading}>
          <Col className="col-span-1 rounded-[10px] border border-gray-200 flex gap-x-2.5 justify-between p-2.5">
            <div className="flex flex-col gap-5">
              <div className="text-[#6B7280] text-[13px] font-bold">
                Tổng khóa học
              </div>
              <div className="text-[24px]">{formatNumber(overview?.all)}</div>
            </div>
            <img src={IconReport1} className="w-[40px] h-[40px]" />
          </Col>
        </Spin>
        <Spin spinning={isLoading}>
          <Col className="col-span-1 rounded-[10px] border border-gray-200 flex gap-x-2.5 justify-between p-2.5">
            <div className="flex flex-col gap-5">
              <div className="text-[#6B7280] text-[13px] font-bold">Online</div>
              <div className="text-[24px]">
                {formatNumber(overview?.online)}
              </div>
            </div>
            <img src={IconReport2} className="w-[40px] h-[40px]" />
          </Col>
        </Spin>
        <Spin spinning={isLoading}>
          <Col className="col-span-1 rounded-[10px] border border-gray-200 flex gap-x-2.5 justify-between p-2.5">
            <div className="flex flex-col gap-5">
              <div className="text-[#6B7280] text-[13px] font-bold">
                Offline
              </div>
              <div className="text-[24px]">
                {formatNumber(overview?.offline)}
              </div>
            </div>
            <img src={IconReport3} className="w-[40px] h-[40px]" />
          </Col>
        </Spin>
      </Row>
    </div>
  );
};

export default Overview;
