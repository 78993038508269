import { useQuery } from '@tanstack/react-query';
import HorizontalBarChart, {
  HorizontalBarChartType,
} from '_common/component/Chart/BarChart/HorizontalBarChart';
import {
  DATE_BACKEND_FORMAT,
  LIMIT_MAX_DATE_TIME,
  LIMIT_MIN_DATE_TIME,
} from '_common/constants/common';
import moment from 'moment';
import Header from 'pages/Dashboard/Header';
import { useContext, useMemo, useState } from 'react';
import { formatDate, RangePicker, Spin } from 'tera-dls';
import { ChartBarCourseOrderContext } from '.';
import UserReportApi from '../../api';
import ModalChart from './ModalChart';

interface ContentProps {
  type?: HorizontalBarChartType;
  chartClassName?: string;
  onClose?: () => void;
}

const Content = (props: ContentProps) => {
  const { chartClassName, type, onClose } = props;
  const isSmallType = type === 'small';
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { contextParams, setContextParams } = useContext(
    ChartBarCourseOrderContext,
  );

  const { data, isFetching, refetch } = useQuery(
    ['get-chart-bar-course', contextParams],
    () => {
      const params = {
        start_date: formatDate(contextParams?.date[0], DATE_BACKEND_FORMAT),
        end_date: formatDate(contextParams?.date[1], DATE_BACKEND_FORMAT),
        type_chart: 'course',
      };
      return UserReportApi.getChartBar({ params });
    },
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const dataChart = useMemo(() => {
    if (!data)
      return {
        datasets: [
          {
            data: [],
          },
        ],
        labels: [],
      };

    const labels = data?.map((value) => value?.date);
    const datasets = data?.map((value) => value?.total);
    return {
      labels,
      datasets: [
        {
          data: datasets,
          backgroundColor: '#31C48D ',
        },
      ],
    };
  }, [data]);

  return (
    <div className="rounded border bg-white flex flex-col p-2.5">
      <Header
        title="Doanh thu đơn bán hàng"
        onClickRefresh={refetch}
        onZoomOut={() => setOpenModal(true)}
        onZoomIn={() => onClose()}
        isSmallType={isSmallType}
        renderComponent={
          <RangePicker
            placeholder={['dd/mm/yyyy', 'dd/mm/yyyy']}
            className="w-[300px]"
            allowClear={false}
            value={contextParams?.date}
            onChange={(value) =>
              setContextParams({ ...contextParams, date: value })
            }
            maxDate={moment(LIMIT_MAX_DATE_TIME)}
            minDate={moment(LIMIT_MIN_DATE_TIME)}
          />
        }
        sub={
          <span className="text-xs">{`${formatDate(
            contextParams?.date[0],
          )} - ${formatDate(contextParams?.date[1])}`}</span>
        }
        isSetting={false}
      />
      <Spin spinning={isFetching}>
        <div className="p-[16px] pb-0 h-full">
          <HorizontalBarChart
            defaultLabelMaxLength={100}
            type={type}
            data={dataChart}
            chartClassName={chartClassName}
            options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
      </Spin>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </div>
  );
};

export default Content;
