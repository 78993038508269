import { useQuery } from '@tanstack/react-query';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import {
  COURSE_ORDER_COMPLETE_STATUS,
  COURSE_ORDER_COMPLETE_STATUS_COLOR_CHART,
} from 'pages/CourseManagement/CourseOrder/constants';
import Header from 'pages/Dashboard/Header';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import startUpIdea from 'styles/images/pages/finance/start_up_idea.png';
import {
  ChartPie,
  formatCurrency,
  formatDate,
  Spin,
  useDetectDevice,
} from 'tera-dls';
import OrderReportApi from '../../api';
import ModalChart from './ModalChart';

interface ChartPieCourseOrderProps {
  type?: string;
  chartClassName?: string;
  params?: any;
  onClose?: () => void;
}

export interface ChartPieCourseOrderRefs {
  refetchData?: () => void;
}

const ChartPieCourseOrder = (props, ref) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { type = 'small', chartClassName, params, onClose } = props;
  const { widthScreen } = useDetectDevice();

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-chart-pie-course', params],
    () => {
      const data = {
        start_date: formatDate(params?.date[0], DATE_BACKEND_FORMAT),
        end_date: formatDate(params?.date[1], DATE_BACKEND_FORMAT),
        type_chart: 'course',
      };
      return OrderReportApi.getChartPie({ params: data });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  console.log('data', data);

  const dataSource = useMemo(() => {
    const labels = data?.status_percentages
      ? data?.status_percentages?.map(
          (value: any) => COURSE_ORDER_COMPLETE_STATUS?.[value?.status],
        )
      : [];
    const colors = data?.status_percentages
      ? data?.status_percentages?.map(
          (value: any) =>
            COURSE_ORDER_COMPLETE_STATUS_COLOR_CHART?.[value?.status],
        )
      : [];

    const datasets: any = {
      data: data?.status_percentages
        ? data?.status_percentages?.map((value: any) => value?.amount)
        : [],
      backgroundColor: colors,
      borderWidth: 0,
    };
    return {
      labels,
      datasets: [datasets],
    };
  }, [data]);

  const options: any = {
    plugins: {
      datalabels: {
        formatter: function (_, values) {
          return `${Number(
            data?.status_percentages[values?.dataIndex]?.percent,
          ).toFixed(1)} %`;
        },
        color: 'white',
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 10,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        enabled: true,
      },
      maintainAspectRatio: false,
    },
  };

  const isSmallType = type === 'small';
  const checkData = (data) =>
    data?.datasets?.[0]?.data.some((value) => value !== 0);

  useImperativeHandle(
    ref,
    () => ({
      refetchData() {
        refetch();
      },
    }),
    [],
  );

  return (
    <div className="rounded-[5px] border bg-white h-full overflow-hidden p-[15px]">
      <Header
        title="Đơn khóa học"
        isSetting={false}
        onZoomOut={() => setOpenModal(true)}
        onZoomIn={() => onClose()}
        isSmallType={isSmallType}
        isRefetch={false}
      />
      <p className="text-[#6B7280] text-[10px]">{`${formatDate(
        params?.date?.[0],
      )} - ${formatDate(params?.date?.[1])}`}</p>

      {data?.total_final_total && (
        <p className="font-semibold text-green-500">
          {formatCurrency(data?.total_final_total)}
        </p>
      )}

      <Spin spinning={isLoading || isRefetching}>
        <div className="flex justify-center">
          {!checkData(dataSource) ? (
            <div className="flex flex-col">
              <img src={startUpIdea} className="mb-[60px]" />
              <p className="text-gray-400 font-normal text-center w-full ">
                Chưa có dữ liệu
              </p>
            </div>
          ) : (
            <ChartPie
              key={widthScreen}
              data={dataSource}
              plugins={['data-labels']}
              options={options}
              className={chartClassName}
            />
          )}
        </div>
      </Spin>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
          params={params}
        />
      )}
    </div>
  );
};

export default forwardRef<ChartPieCourseOrderRefs, ChartPieCourseOrderProps>(
  ChartPieCourseOrder,
);
