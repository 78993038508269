import React, { useState } from 'react';
import Content from './Content';
import moment from 'moment';
export const ChartBarSaleOrderContext = React.createContext(null);

const ChartBarSaleOrder = () => {
  const [contextParams, setContextParams] = useState({
    date: [moment().subtract(1, 'months'), moment()],
  });
  return (
    <ChartBarSaleOrderContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <Content chartClassName="!h-[350px]" type="small" />
    </ChartBarSaleOrderContext.Provider>
  );
};

export default ChartBarSaleOrder;
